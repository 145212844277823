import Layout from "../../../components/layout/layout.js";

import Resources from "../../../components/PIResources/index.js";

function ResourcesListPage({ component, ...rest }) {
  return (
    <Layout Component={Resources} controlsDisabled={true} {...rest} />
  );
}

export default ResourcesListPage;
