import { useEffect, useState } from "react";
import styles from "./Modal.module.scss";
import { MdClose } from "react-icons/md";
import { ListGroup, InputGroup, FormControl } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import { db } from "../../firebase";

export const SelectCompareProfile = ({ id, handleClose }) => {
  const history = useHistory();
  const { isAdmin, isSuperAdmin, getUserInfoData } = useAuth();

  const [profileData, setProfileData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProfile, setSelectedProfile] = useState(null);

  useEffect(() => {
    handleGetPlayerProfiles();
  }, []);

  const handleGetPlayerProfiles = async () => {
    const response = await db.collection("player-profile").get();
    const data = response.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    let filteredData;
    if (isAdmin() || isSuperAdmin()) {
      filteredData = data;
    } else {
      filteredData = data.filter((el) => {
        if (
          el["viewing_privileges"] &&
          el["viewing_privileges"].includes(getUserInfoData().memberType)
        ) {
          return el;
        }
      });
    }

    setProfileData(filteredData);
    setFilteredData(filteredData);
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);

    // Filter player profiles based on search term (first or last name)
    const filtered = profileData.filter((profile) =>
      `${profile.FirstName} ${profile.LastName}`.toLowerCase().includes(searchValue)
    );
    setFilteredData(filtered);
  };

  const handleProfileSelect = (item) => {
    setSelectedProfile(item);
  };

  const handleConfirmCompare = () => {
    if (!selectedProfile) return;
    handleClose();
    history.replace(`/player-profile/preview/${id}/${selectedProfile.id}`);
    window.location.reload();
  };

  return (
    <div className={styles.modalContainer}>
      <div onClick={handleClose} className={styles.clickAway}></div>
      <div className={styles.modal}>
        <button onClick={handleClose}>
          <MdClose />
        </button>

        {/* Autocomplete search input */}
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Search for a player..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </InputGroup>

        {/* Scrollable list */}
        <div className={styles.scrollableList}>
          <ListGroup>
            {filteredData?.map((el) => (
              <ListGroup.Item
                key={el.id}
                variant={selectedProfile?.id === el.id ? "secondary" : ""}
                action
                onClick={() => handleProfileSelect(el)}
                style={{ textAlign: "left" }} // Ensure text is left-aligned
              >
                {el.FirstName} {el.LastName}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>

        <div className={styles.btns}>
          <button onClick={handleClose}>Cancel</button>
          <button
            style={{
              background: !selectedProfile ? "grey" : "",
              cursor: !selectedProfile ? "not-allowed" : "pointer",
            }}
            disabled={!selectedProfile}
            onClick={handleConfirmCompare}
          >
            Compare
          </button>
        </div>
      </div>
    </div>
  );
};
