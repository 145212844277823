import React, { useState, useEffect } from "react";
import styles from "./Packet.module.scss";
import Header from "../header/header";
import { FaEye } from "react-icons/fa";
import { CustomSpinner } from "../shared/Spinner";
import { db } from "../../firebase";

export default function ProPacketPage() {
  const [loading, setLoading] = useState(false);
  const [packetData, setPacketData] = useState(null);

  useEffect(() => {
    getPacketData();
  }, []);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };

  const getPacketData = async () => {
    setLoading(true);
    try {
      const response = await db.collection("resources").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setPacketData(data);
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };

  if (loading) {
    return <CustomSpinner />;
  }

  if (!packetData && !loading) {
    return <div>Loading Resources data</div>;
  }

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <div className={styles.title}>
          <h4>Resources Library</h4>
          <p>This section contains our resources for staff</p>
        </div>
        <hr />
        <div className={styles.table}>
          <div className={styles.tableHeader}>
            
          </div>
          <div className={styles.row}>
            <div>Event</div>
            <div>Type</div>
            <div>Date</div>
            <div>View</div>
          </div>
          {packetData.map((item) => (
            <div key={item.id} className={styles.row}>
              <div>{item.title}</div>
              <div>{item.type}</div>
              <div>{formatDate(item.date)}</div>
              <div>
                <a
                  href={item.pdfUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEye style={{ color: "grey", marginRight: "5px" }} />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
