import Layout from "../../../components/layout/layout.js";

import PlayerPermissions from "../../../components/PlayerPermissions/index.js";


function PlayerPermissionsPage({ component, ...rest }) {
  return (
    <Layout Component={PlayerPermissions} controlsDisabled={true} {...rest} />
  );
}

export default PlayerPermissionsPage;
