import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Spinner,
  FormControl,
  InputGroup,
  Form,
  Alert
} from "react-bootstrap";
import { FaSearch, FaSortUp, FaSortDown, FaFilter, FaInfoCircle } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { db } from "../../firebase";
import Header from "../header/header";
import styles from "./Packet.module.scss";

const PlayerPermissionsPage = () => {
  const [profiles, setProfiles] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingPrivileges, setEditingPrivileges] = useState([]);
  //finishe setting up bulk privilage const
  const [editingBulkPrivileges, setEditingBulkPrivileges] = useState([]);
  // tracks for changes within bulk editing
  const [hasChanges, setHasChanges] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [showYearDropdown, setShowYearDropdown] = useState(false);
  const [ageSortDirection, setAgeSortDirection] = useState(null);
  const [isBulkEditing, setIsBulkEditing] = useState(false); // New state for bulk editing mode

  const privilegesList = [
    { label: "NCAA", value: "ncaa" },
    { label: "Executive", value: "executive" },
    { label: "Demo", value: "demo" },
    { label: "Draft", value: "Draft" },
    { label: "Recertification", value: "ncaa_admin" },
  ];

  const getPlayerProfiles = async () => {
    setLoading(true);
    try {
      const response = await db.collection("player-profile").get();
      const data = response.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      data.sort((a, b) => {
        const lastNameA = a.Title.split(" ").slice(-1)[0].toLowerCase();
        const lastNameB = b.Title.split(" ").slice(-1)[0].toLowerCase();
        return lastNameA.localeCompare(lastNameB);
      });

      setProfiles(data);
    } catch (error) {
      console.error("Error fetching profiles:", error);
    } finally {
      setLoading(false);
    }
  };

  const getYears = async () => {
    try {
      const response = await db.collection("year-dropdown").orderBy("order").get();
      const yearsData = response.docs.map((doc) => ({
        name: doc.data().name,
        value: doc.data().value,
      }));
      setYears(yearsData);
    } catch (error) {
      console.error("Error fetching years:", error);
    }
  };

  useEffect(() => {
    getPlayerProfiles();
    getYears();
  }, []);

  const toggleSearch = () => {
    setShowSearch(!showSearch);
    setSearchTerm("");
  };

  const filteredProfiles = profiles
    .filter((profile) => profile.Title.toLowerCase().includes(searchTerm.toLowerCase()))
    .filter((profile) => !selectedYear || profile.Year === selectedYear);

  const handleAgeSort = () => {
    let newSortDirection;
    if (ageSortDirection === null) {
      newSortDirection = "asc";
    } else if (ageSortDirection === "asc") {
      newSortDirection = "desc";
    } else {
      newSortDirection = null;
    }

    const sortedProfiles = [...profiles];

    if (newSortDirection === "asc") {
      sortedProfiles.sort((a, b) => {
        const ageA = isNaN(parseFloat(a.Age)) ? -1 : parseFloat(a.Age);
        const ageB = isNaN(parseFloat(b.Age)) ? -1 : parseFloat(b.Age);
        return ageA - ageB;
      });
    } else if (newSortDirection === "desc") {
      sortedProfiles.sort((a, b) => {
        const ageA = isNaN(parseFloat(a.Age)) ? -1 : parseFloat(a.Age);
        const ageB = isNaN(parseFloat(b.Age)) ? -1 : parseFloat(b.Age);
        return ageB - ageA;
      });
    }

    setProfiles(sortedProfiles);
    setAgeSortDirection(newSortDirection);
  };

  const formatAge = (age) => {
    return age === undefined || age === null || isNaN(age) ? "--" : Number(age).toFixed(1);
  };

  const handleYearSelect = (year) => {
    setSelectedYear(year);
    setShowYearDropdown(false);
  };
  
  const clearYearFilter = () => {
    setSelectedYear(null);
    setShowYearDropdown(false);
  };

  const enableEditing = (rowId, privileges) => {
    setEditingRowId(rowId);
    setEditingPrivileges(privileges);
  };

  const handleCheckboxChange = (privilegeValue) => {
    setEditingPrivileges((prevPrivileges) =>
      prevPrivileges.includes(privilegeValue)
        ? prevPrivileges.filter((priv) => priv !== privilegeValue)
        : [...prevPrivileges, privilegeValue]
    );
  };

  const handleSaveChanges = async (rowId) => {
    try {
      await db.collection("player-profile").doc(rowId).update({
        viewing_privileges: editingPrivileges,
      });
      setEditingRowId(null);
      setEditingPrivileges([]);
      getPlayerProfiles();
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingPrivileges([]);
  };

  const toggleYearDropdown = (e) => {
    e.stopPropagation();
    setShowYearDropdown((prev) => !prev);
  };

  const handleBulkUpdateClick = () => {
    const confirmed = window.confirm("Would you like to bulk update viewing profile privileges?");
    if (confirmed) {
      setIsBulkEditing(true); // Enable bulk editing mode
    }
  };

  const exitBulkEditing = () => {
    setIsBulkEditing(false); // Exit bulk editing mode
  };


  // Handle checkbox changes
  const handleBulkCheckboxChange = (profileId, privilege) => {
    // Get the current privileges either from editingBulkPrivileges or profile's original privileges
    const currentPrivileges = editingBulkPrivileges[profileId]
      ? [...editingBulkPrivileges[profileId]] // If editing state exists, use it
      : profiles.find((profile) => profile.id === profileId)?.viewing_privileges || []; // Otherwise, use original privileges
  
    // Toggle privilege: Add if not present, remove if already selected
    const updatedPrivileges = currentPrivileges.includes(privilege)
      ? currentPrivileges.filter((p) => p !== privilege) // Remove privilege
      : [...currentPrivileges, privilege]; // Add privilege
  
    // Update the editingBulkPrivileges state with the updated privileges
    setEditingBulkPrivileges((prev) => ({
      ...prev,
      [profileId]: updatedPrivileges,
    }));
  
    // Detect changes
    setHasChanges(true);
  };

  const handleBulkSaveChanges = async () => {
    try {
      for (const [profileId, newPrivileges] of Object.entries(editingBulkPrivileges)) {
        await db.collection("player-profile").doc(profileId).update({
          viewing_privileges: newPrivileges,
        });
      }
      alert("Viewing privileges updated successfully!");
      setEditingBulkPrivileges({});
      setHasChanges(false); // Reset change detection
      getPlayerProfiles(); // Refresh profiles after updating
    } catch (error) {
      alert("Failed to update privileges");
      console.error("Error updating privileges:", error);
    }
  };

  const handleCancelBulkEditing = () => {
    setEditingBulkPrivileges({});
    setHasChanges(false); // Reset changes
  };


  const renderPrivilegeCheckboxes = (privileges, profile) => {
    return privilegesList.map((privilege) => (
      <td key={privilege.value}>
        <Form.Check
          type="checkbox"
          checked={editingBulkPrivileges[profile.id]?.includes(privilege.value) ?? privileges.includes(privilege.value)}
          onChange={() => handleBulkCheckboxChange(profile.id, privilege.value)}
        />
      </td>
    ));
  };
  

  // Conditional rendering based on isBulkEditing
  if (isBulkEditing) {
    return (
      <div className={styles.container}>
        <Header />
        <div className={styles.content}>
          <div className={styles.title}>
                  <h4>Bulk Viewing Privileges</h4>
                  <p>This section is used to bulk edit viewing privileges.</p>
          </div>
        <hr />

        <Alert variant="danger" className={styles.warningHeader}>
              <span>
                You are now editing viewing privileges in bulk, please be careful. Hit the close button to exit this view
              </span>
              <AiOutlineClose
                className={styles.closeIcon}
                onClick={exitBulkEditing}
                style={{ cursor: "pointer", marginLeft: "10px" }}
              />
            </Alert>

        {loading ? (
          <Spinner animation="border" />
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th style={{ position: "relative" }}>
                  {showSearch ? (
                    <InputGroup className={styles.searchBox}>
                      <FormControl
                        placeholder="Search player"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <AiOutlineClose
                        className={styles.closeIcon}
                        onClick={toggleSearch}
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                      />
                    </InputGroup>
                  ) : (
                    <>
                      Player Name
                      <FaSearch
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                        onClick={toggleSearch}
                      />
                    </>
                  )}
                </th>
                <th>Team</th>
                <th style={{ position: "relative" }}>
                  Year
                  <FaFilter
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                    onClick={toggleYearDropdown}
                  />
                  {showYearDropdown && (
                    <ul className={styles.yearList}>
                      <li onClick={clearYearFilter} style={{ cursor: "pointer" }}>
                        Clear Filter
                      </li>
                      {years.map((year) => (
                        <li
                          key={year.value}
                          onClick={() => handleYearSelect(year.value)}
                          style={{ cursor: "pointer" }}
                        >
                          {year.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </th>
                <th onClick={handleAgeSort} style={{ cursor: "pointer" }}>
                Age
                {ageSortDirection === "asc" ? (
                  <FaSortUp style={{ marginLeft: "5px" }} />
                ) : ageSortDirection === "desc" ? (
                  <FaSortDown style={{ marginLeft: "5px" }} />
                ) : null}
              </th>
                {privilegesList.map((privilege) => (
                  <th key={privilege.value}>{privilege.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredProfiles.map((profile) => (
                <tr key={profile.id}>
                  <td>{profile.Title}</td>
                  <td>{profile.Team || "--"}</td>
                  <td>{profile.Year || "--"}</td>
                  <td>{formatAge(profile?.Age)}</td>
                  {renderPrivilegeCheckboxes(profile.viewing_privileges, profile)}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <Button
          variant="primary"
          onClick={handleBulkSaveChanges}
          disabled={Object.keys(editingBulkPrivileges).length === 0} // Check bulk privileges instead of single row editing
        >
          Save Changes
        </Button>
        {hasChanges && (
            <Button
              variant="secondary"
              onClick={handleCancelBulkEditing}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          )}
          </div>
      </div>
    );
  }

  // Default view when not in bulk editing
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
          <div className={styles.title}>
                  <h4>Viewing Privileges</h4>
                  <p>This section allows to indivudually edit viewing priviliges.</p>
          </div>
        <hr />
      
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th style={{ position: "relative" }}>
                {showSearch ? (
                  <InputGroup className={styles.searchBox}>
                    <FormControl
                      placeholder="Search player"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <AiOutlineClose
                      className={styles.closeIcon}
                      onClick={toggleSearch}
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                    />
                  </InputGroup>
                ) : (
                  <>
                    Player Name
                    <FaSearch
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      onClick={toggleSearch}
                    />
                  </>
                )}
              </th>
              <th>Team</th>
              <th style={{ position: "relative" }}>
                Year
                <FaFilter
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                  onClick={toggleYearDropdown}
                />
                {showYearDropdown && (
                  <ul className={styles.yearList}>
                    <li onClick={clearYearFilter} style={{ cursor: "pointer" }}>
                      Clear Filter
                    </li>
                    {years.map((year) => (
                      <li
                        key={year.value}
                        onClick={() => handleYearSelect(year.value)}
                        style={{ cursor: "pointer" }}
                      >
                        {year.name}
                      </li>
                    ))}
                  </ul>
                )}
              </th>
              <th onClick={handleAgeSort} style={{ cursor: "pointer" }}>
                Age
                {ageSortDirection === "asc" ? (
                  <FaSortUp style={{ marginLeft: "5px" }} />
                ) : ageSortDirection === "desc" ? (
                  <FaSortDown style={{ marginLeft: "5px" }} />
                ) : null}
              </th>
              {privilegesList.map((privilege) => (
                <th key={privilege.value}>{privilege.label}</th>
              ))}
              <th>
                Actions
                <FaInfoCircle
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                  onClick={handleBulkUpdateClick}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredProfiles.map((profile) => (
              <tr key={profile.id}>
                <td>{profile.Title}</td>
                <td>{profile.Team || "--"}</td>
                <td>{profile.Year || "--"}</td>
                <td>{formatAge(profile?.Age)}</td>
                {privilegesList.map((privilege) => (
                  <td key={privilege.value}>
                    <Form.Check
                      type="checkbox"
                      disabled={editingRowId !== profile.id}
                      checked={
                        editingRowId === profile.id
                          ? editingPrivileges.includes(privilege.value)
                          : profile.viewing_privileges.includes(privilege.value)
                      }
                      onChange={() => handleCheckboxChange(privilege.value)}
                    />
                  </td>
                ))}
                <td>
                  {editingRowId === profile.id ? (
                    <>
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => handleSaveChanges(profile.id)}
                      >
                        Save
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={handleCancel}
                        className="ml-2"
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => enableEditing(profile.id, profile.viewing_privileges)}
                    >
                      Edit
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      </div>
    </div>
  );
};

export default PlayerPermissionsPage;
