import { Link } from "react-router-dom";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import styles from "./Sidebar.module.scss";
import { ImArrowLeft2, ImArrowRight2 } from "react-icons/im";
import { GoHome } from "react-icons/go";
import { useAuth } from "../../Context/AuthContext";

import logoSVG from "../../Assets/icons/PI_Logo.svg";

export default function Sidebar({
  toggled,
  collapsed,
  setCollapsed,
  handleToggleSidebar,
}) {
  const { isAdmin, isSuperAdmin, isDemo } = useAuth();

  return (
    <ProSidebar
      onToggle={handleToggleSidebar}
      breakPoint="md"
      toggled={toggled}
      collapsed={collapsed}
      style={{
        zIndex: "101",
      }}
    >
      <SidebarHeader
      style={{
        backgroundColor: "black",
        color: "white",
      }}
      >
        <button className={styles.btn} onClick={() => setCollapsed(!collapsed)}>
          {!collapsed ? <ImArrowLeft2 /> : <ImArrowRight2 />}
        </button>
      </SidebarHeader>
      <SidebarContent
      style={{
        backgroundColor: "black",
        color: "white",
      }}
      >
        
        <Menu iconShape="circle">
          <MenuItem icon={<GoHome />}>
            <Link to="/" 
             style={{
            backgroundColor: "black",
            color: "white",
          }}> Dashboard</Link>
          </MenuItem>
          {(isAdmin() || isSuperAdmin()) && (
            <SubMenu title="Add">
              <MenuItem>
                <Link to="/player-profile/create"> Player Profile</Link>
              </MenuItem>

              <MenuItem>
                <Link to="/report/create">Report</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/background-overview/create">
                  Background Overview
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/scouting-overview/create"> Scouting Overview</Link>
              </MenuItem>
            </SubMenu>
          )}
          <MenuItem >
            <Link to="/player-profile" 
            style={{
              backgroundColor: "black",
              color: "white",
            }}>Smart Search</Link>
          </MenuItem>
          {(isAdmin() || isSuperAdmin()) && (
            <SubMenu title="Overviews">
              <MenuItem>
                <Link to="/background-overview-feed">Background Overviews</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/scouting-overview-feed">Scouting Overviews</Link>
              </MenuItem>
            </SubMenu>
          )}
          
          {!isDemo() && (
          <MenuItem >
            <Link to="/components/PIPackets" 
            style={{
              backgroundColor: "black",
              color: "white",
            }}>PI Packets</Link>
          </MenuItem>
          )}

          {(isAdmin() || isSuperAdmin()) && (
            
          <MenuItem>
            <Link to="/components/PIResources" 
            style={{
              backgroundColor: "black",
              color: "white",
            }}>PI Resources</Link>
          </MenuItem>
            
          )}


          <MenuItem >
            <a href="https://www.prospectiveinsight.com/q-and-a-series" 
            target="_blank" rel="noopener noreferrer"
            style={{
              backgroundColor: "black",
              color: "white",
            }}>Q&A Series</a>
          </MenuItem>
          <MenuItem >
            <a href="https://www.prospectiveinsight.com/pi-pulse" 
            target="_blank" rel="noopener noreferrer"
            style={{
              backgroundColor: "black",
              color: "white",
            }}>PI Pulse</a>
          </MenuItem>
          {/*
          <MenuItem >
            <a href="https://www.youtube.com/proinsight" 
            target="_blank" rel="noopener noreferrer"
            style={{
              backgroundColor: "black",
              color: "white",
            }}>Youtube</a>
          </MenuItem>
          <MenuItem >
            <a href="https://www.youtube.com/proinsight" 
            target="_blank" rel="noopener noreferrer"
            style={{
              backgroundColor: "black",
              color: "white",
            }}>X</a>
          </MenuItem>
          <MenuItem >
            <a href="https://www.youtube.com/proinsight" 
            target="_blank" rel="noopener noreferrer"
            style={{
              backgroundColor: "black",
              color: "white",
            }}>Instagram</a>
          </MenuItem>
          */}
          

          {(isSuperAdmin()) && (
            <SubMenu title="Stats">
              <MenuItem>
                <Link to="/stats/import">Stats Import</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/stats/add">Add Stats</Link>
              </MenuItem>
            </SubMenu>
          )}
          {isSuperAdmin() && (
            <SubMenu title="Articles">
              <MenuItem>
                <Link to="/article/published">Published</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/article/drafted">Drafted</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/article/add">Add</Link>
              </MenuItem>
              {/* <MenuItem>
          <Link to="/article/edit">Edit</Link>
        </MenuItem> */}
              {/* <MenuItem>
          <Link to="/article/Delete">Delete</Link>
        </MenuItem> */}
            </SubMenu>
          )}
          {isSuperAdmin() && (
            <SubMenu title="Subscription">
              <MenuItem>
                <Link to="/subscription/members">Members</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/subscription/plans">Plans</Link>
              </MenuItem>
            </SubMenu>
          )}
          {(isSuperAdmin()) && (
            <MenuItem>
              <Link to="/contacts" 
               style={{
                backgroundColor: "black",
                color: "white",
              }}> Contacts</Link>
            </MenuItem>
          )}

          {(isSuperAdmin()) && (
            <SubMenu title={<Link to="/tickets"
            style={{
              backgroundColor: "black",
              color: "white",
            }}>Tickets</Link>}>
              <MenuItem>
                <Link to="/ticket/create">Create Ticket</Link>
              </MenuItem>
              <MenuItem>Edit</MenuItem>
              <MenuItem>Delete</MenuItem>
            </SubMenu>
          )}
          {( isSuperAdmin()) && (
            <SubMenu title={<Link to="/calendar" 
            style={{
              backgroundColor: "black",
              color: "white",
            }}>Calendar</Link>}>
              <MenuItem>
                <Link to="/calendar/create">Add Event</Link>
              </MenuItem>
              <MenuItem>Edit</MenuItem>
              <MenuItem>Delete</MenuItem>
            </SubMenu>
          )}
          {(isSuperAdmin()) && (
            <SubMenu title="Components">
              <MenuItem>
                <Link to="/components/nationality">Nationality Dropdown</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/components/year">Year Dropdown</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/components/year-range">Year Dropdown (Range)</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/components/height">Height Dropdown</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/components/wingspan">Wingspan Dropdown</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/components/weight">Weight Dropdown</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/components/aauTeams">AAU Teams</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/components/role">Role Dropdown</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/components/school">Schools/Teams</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/components/college-level">College Level Dropdown</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/components/packets">Add PI Packets</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/components/resources">Add Resources</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/components/media-library">Media Library</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/components/player-permissions">Viewing Privileges</Link>
              </MenuItem>
            </SubMenu>
          )}
        </Menu>
        <div className={styles.logoContainer}>
          <img
            src={logoSVG}
            alt="Logo"
            className={styles.logo}
            style={{ width: "40%", height: "auto", paddingTop: '50px' }} // Resize the logo
          />
        </div>
      </SidebarContent>
      <SidebarFooter></SidebarFooter>
    </ProSidebar>
  );
}
