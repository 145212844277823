import React, { useState, useEffect } from "react";
import styles from "./Dashboard.module.scss";
import moment from "moment";
import { db } from "../../firebase";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function ActivityFeed() {
  const [activityFeed, setActivityFeed] = useState([]);
  const [lastVisible, setLastVisible] = useState(null); // To track the last document
  const [loading, setLoading] = useState(true); // Loading state
  const [loadingMore, setLoadingMore] = useState(false); // Load More state
  const [hasMore, setHasMore] = useState(true); // Disable Load More when no more data

  const getActivityFeed = (loadMore = false) => {
    if (loadMore) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }

    let query = db.collection("activity")
      .orderBy("timestamp", "desc")
      .limit(20);

    if (loadMore && lastVisible) {
      query = query.startAfter(lastVisible);
    }

    query.get().then((snapshot) => {
      const feed = snapshot.docs.map((doc) => ({ ...doc.data() }));
      const lastDoc = snapshot.docs[snapshot.docs.length - 1];

      setActivityFeed((prevFeed) => loadMore ? [...prevFeed, ...feed] : feed);
      setLastVisible(lastDoc);
      setHasMore(snapshot.docs.length === 20); // Disable Load More if fewer than 20
      setLoading(false);
      setLoadingMore(false);
    });
  };

  useEffect(() => {
    getActivityFeed();
  }, []);

  // The loadMore function triggers fetching more items
  const loadMore = () => {
    if (hasMore) {
      getActivityFeed(true); // Pass true to indicate loading more items
    }
  };

  const renderSkeletonLoader = () => (
    <tbody>
      {[...Array(5)].map((_, index) => (
        <tr key={index} className={styles.skeletonRow}>
          <td colSpan="5">
            <div className={styles.skeletonCell}></div>
          </td>
        </tr>
      ))}
    </tbody>
  );

  return (
    <div className={styles.feed}>
      <p>Activity Feed</p>
      <div className={styles.tableContainer}>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Player</th>
              <th>Type</th>
              <th>Editor</th>
            </tr>
          </thead>
          {loading ? (
            renderSkeletonLoader()
          ) : (
            <tbody>
              {activityFeed.map((item, index) => (
                <tr key={index}>
                  <td>{moment.unix(item.timestamp).format("l")}</td>
                  <td>{moment.unix(item.timestamp).format("hh:mm:ss A")}</td>
                  <td>
                    {item.playerID ? (
                      <Link to={`/player-profile/detail/${item.playerID}`}>
                        {item.player}
                      </Link>
                    ) : (
                      item.player
                    )}
                  </td>
                  <td>{item.desc}</td>
                  <td>{item.editor}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        
        <div className={styles.centeredButton}>
          {loadingMore ? (
            <p>Loading more...</p> // Display "Loading more..." during the Load More fetch
          ) : (
            <button onClick={loadMore}>Load More</button>
          )}
        </div>
      </div>
    </div>
  );
}
