import React, { useEffect, useState, useRef } from "react";
import { cloneDeep } from "lodash";
import styles from "./Table.module.scss";
import hStyles from "../header/Header.module.scss";
import "./index.css";
import previewImage from "../../Assets/images/piLogo.png";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

// firebase
import { db } from "../../firebase";
import { useAuth } from "../../Context/AuthContext";
import { CSVLink } from "react-csv";
import csvtojson from "csvtojson";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";

// icons
import { FaSearch, FaSearchengin } from "react-icons/fa";
import { Table, Image, Row, Col } from "react-bootstrap";
import { Button } from "primereact/button";

// header
import Header from "../header/header";
import { useHistory } from "react-router-dom";
import moment from "moment";
// components
import { MultiSelect } from "primereact/multiselect";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";

import FiltersComponent from "./filter";

// height functions
const feetInchesToInches = (height) => {
  const [feet, inches] = height.split("'").map(Number);
  return feet * 12 + inches;
};

const inchesToFeetInches = (inches) => {
  const feet = Math.floor(inches / 12);
  const remainder = inches % 12;
  return `${feet}'${remainder.toFixed(2)}`;
};

const DEFAULT_HEIGHT = [54, 96];
const DEFAULT_WEIGHT = [100, 300];
const DEFAULT_WINGSPAN = [60, 102];

export const PlayerProfile = () => {
  const { isAdmin, isSuperAdmin, getUserInfoData } = useAuth();
  const playerProfileData = useRef([]);
  const [countries, setCountries] = useState([]);

  const [stateandcountries, setStateCountries] = useState([]);

  const [positions, setPositions] = useState([]);
  const [years, setYears] = useState([]);
  const [aauTeams, setAAUTeams] = useState([]);
  const [teams, setTeams] = useState([]);
  const [affliations, setAffliations] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [csvReport, setCsvReport] = useState(null);
  const selectCSV = useRef(null);

  const [isFetching, setIsFetching] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [search, setSearch] = useState("");

  // height slider
  const [heightRange, setHeightRange] = useState(DEFAULT_HEIGHT);
  const [weightRange, setWeightRange] = useState(DEFAULT_WEIGHT); // Example weight range
  const [wingspanRange, setWingspanRange] = useState(DEFAULT_WINGSPAN); // Example wingspan range
  //

  const handleHeightChange = (event, newValue) => {
    // setSortBy("");
    setHeightRange(newValue);
  };

  const handleWeightChange = (event, newValue) => {
    // setSortBy("");
    setWeightRange(newValue);
  };

  const handleWingspanChange = (event, newValue) => {
    // setSortBy("");
    setWingspanRange(newValue);
  };

  const [filterBy, setFilterBy] = useState({
    country: [],
    position: [],
    year: [],
    team: [],
    aauTeam: [],
    affliation: [],
    stateandcountry: [],
    status: [],
  });
  const clearAllFilters = () => {
    setFilterBy({
      country: [],
      position: [],
      year: [],
      team: [],
      aauTeam: [],
      affliation: [],
      stateandcountry: [],
      status: [],
    });
  };

  const toast = useRef(null);
  const observerTarget = useRef(null);

  useEffect(() => {
    getPlayerProfiles();
    getCountries();
    getStateCountries();
    getPositions();
    getYears();
    getTeams();
    getAAUTeams();
    getAffliations();
    getStatuses();

    setSortBy("LASC");
  }, []);

  useEffect(() => {
    console.log("Do something after pageNumber has changed", pageNumber);
    if (!isLoading && search === "") getPlayerProfiles();
  }, [pageNumber]);

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const handleDeleteAllData = async () => {
    let response = await db.collection("player-profile").get();
    let data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    let response2 = data.map(async (item) => {
      let response = await db
        .collection("player-profile")
        .doc(item.id)
        .delete();
      return response;
    });
    response2 = await Promise.all(response2);
    if (response2.length > 0) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Player Profile Deleted Successfully",
        life: 3000,
      });
      getPlayerProfiles();
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong",
        life: 3000,
      });
    }
  };
  const handleAcceptFileUpload = async (payload) => {
    // upload all the data Array multiple objects to firebase
    let response = payload.map(async (item) => {
      let playerRef = db.collection("player-profile").doc(item.id);
      let response = await playerRef.set(item);
      return response;
    });

    response = await Promise.all(response);

    if (response.length > 0) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Player Profile Added Successfully",
        life: 3000,
      });
      getPlayerProfiles();
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong",
        life: 3000,
      });
    }
  };

  const confirm1 = (payload) => {
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      // handle acccept
      accept: () => {
        handleAcceptFileUpload(payload);
      },

      reject,
    });
  };

  const confirm2 = () => {
    confirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {
        handleDeleteAllData();
      },
      reject,
    });
  };
  const sortByElements = [
    ...(isAdmin() || isSuperAdmin()
      ? [
          { name: "Date Created (Newest)", value: "DCDSC" },
          { name: "Date Created (Oldest)", value: "DCASC" },
         
        ]
      : []),
    // sort by Projection Range
    { name: "Projection Range (ASC)", value: "PRASC" },
    { name: "Projection Range (DSC)", value: "PRDSC" },
    { name: "Last Name (ASC)", value: "LASC" },
    { name: "Last Name (DSC)", value: "LDSC" },
    { name: "First Name (ASC)", value: "NASC" },
    { name: "First Name (DSC)", value: "NDSC" },
    // sort by Team
    { name: "Team (ASC)", value: "TASC" },
    { name: "Team (DSC)", value: "TDSC" },
    // sort by year
    { name: "Year (ASC)", value: "YASC" },
    { name: "Year (DSC)", value: "YDSC" },
    // sort by position
    { name: "Position (ASC)", value: "PASC" },
    { name: "Position (DSC)", value: "PDSC" },
    // sort by age
    { name: "Age (ASC)", value: "AASC" },
    { name: "Age (DSC)", value: "ADSC" },
    // sort by state
    { name: "State (ASC)", value: "SASC" },
    { name: "State (DSC)", value: "SDSC" },
  ];

  const history = useHistory();

  useEffect(() => {
    getPlayerProfiles();
    getCountries();
    getPositions();
    getYears();
    getTeams();
    getStateCountries();
    getAAUTeams();
    getAffliations();
    getStatuses();
  }, []);

  const getStateCountries = async () => {
    try {
      let response = await db.collection("state-country-dropdown").get();
      let stateandcountries = response.docs.map((doc) => ({ ...doc.data() }));

      stateandcountries.sort((a, b) => {
        if (a.name.length === 2 && b.name.length === 2) {
          return a.name.localeCompare(b.name);
        }
        if (a.name.length === 2) {
          return -1;
        }
        if (b.name.length === 2) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      });

      setStateCountries([...stateandcountries]);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getCountries = async () => {
    try {
      let response = await db.collection("nationality-dropdown").get();
      let countries = response.docs.map((doc) => ({ ...doc.data() }));

      // Move United States to the top
      countries.sort((a, b) => {
        if (a.name === "United States of America") return -1;
        if (b.name === "United States of America") return 1;
        return a.name.localeCompare(b.name);
      });

      setCountries([...countries]);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getPositions = async () => {
    try {
      let response = await db.collection("position-dropdown").get();
      let data = response.docs.map((doc) => ({ ...doc.data() }));

      // Define the desired order of positions
      const desiredOrder = ["Guard", "Wing", "Forward", "Big"];

      // Sort positions based on the desired order
      data.sort((a, b) => {
        return desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name);
      });

      setPositions([...data]);
    } catch (error) {
      console.log(error, "error");
    }
  };
  const getYears = async () => {
    try {
      let response = await db.collection("year-dropdown").get();
      let data = response.docs.map((doc) => ({ ...doc.data() }));

      // Separate "INT'L" and other years
      const intlYear = data.find((year) => year.name === "INT'L");
      const otherYears = data.filter((year) => year.name !== "INT'L");

      // Sort other years in ascending order
      otherYears.sort((a, b) => parseInt(a.name) - parseInt(b.name));

      // Combine "INT'L" and other years
      const sortedYears = [intlYear, ...otherYears];

      setYears([...sortedYears]);
    } catch (error) {
      console.log(error, "error");
    }
  };
  const getTeams = async () => {
    try {
      let response = await db.collection("school-dropdown").get();
      let data = response.docs.map((doc) => ({ ...doc.data() }));
  
      // Sort Teams in alphabetical order
      data.sort((a, b) => a.name.localeCompare(b.name));
  
      // Check if the user is an admin or super-admin
      if (isAdmin() || isSuperAdmin()) {
        // Add "Transfer Portal" option to the teams list
        data.unshift({ name: "Transfer Portal", value: "Transfer Portal" });
      }
  
      setTeams([...data]);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getAAUTeams = async () => {
    try {
      let response = await db.collection("AAUTeam-dropdown").get();
      let data = response.docs.map((doc) => ({ ...doc.data() }));

      // Sort AAU Teams in alphabetical order
      data.sort((a, b) => a.name.localeCompare(b.name));

      setAAUTeams([...data]);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getAffliations = async () => {
    try {
      let response = await db.collection("affiliation-dropdown").get();
      let data = response.docs.map((doc) => ({ ...doc.data() }));

      // Define the desired order of affiliations
      const desiredOrder = [
        "adidas",
        "Nike",
        "Puma",
        "Under Armour",
        "Independent",
      ];

      // Sort affiliations based on the desired order
      data.sort((a, b) => {
        return desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name);
      });

      setAffliations([...data]);
    } catch (error) {
      console.log(error, "error");
    }
  };
  // update
  const getStatuses = async () => {
    let response = await db.collection("status-dropdown").get();
    let data = response.docs.map((doc) => ({ ...doc.data() }));
    setStatuses([...data]);
  };

  const search_filter_sort = (search_value) => {
    let searchedProfiles = cloneDeep(playerProfileData.current);

    // Search by first name or last name
    if (search_value.length > 0) {
      searchedProfiles = searchedProfiles.filter((data) => {
        const fullName = `${data.FirstName} ${data.LastName}`.toLowerCase();
        return fullName.includes(search_value.toLowerCase());
      });
    }

    // Filter by other criteria
    let filteredProfs = filterProfiles(searchedProfiles, filterBy);

    // Sort the filtered profiles
    if (sortBy !== "") {
      let sortFn;
      if (sortBy === "NDSC")
        sortFn = sortNames(true, false); // First Name (DSC)
      else if (sortBy === "NASC")
        sortFn = sortNames(false, false); // First Name (ASC)
      else if (sortBy === "LDSC")
        sortFn = sortNames(true, true); // Last Name (DSC)
      else if (sortBy === "LASC")
        sortFn = sortNames(false, true); // Last Name (ASC)
      //year
      else if (sortBy === "YASC") sortFn = sortYears(true);
      else if (sortBy === "YDSC") sortFn = sortYears(false);
      //position
      else if (sortBy === "PASC") sortFn = sortPositions(false);
      else if (sortBy === "PDSC") sortFn = sortPositions(true);
      // age
      else if (sortBy === "AASC") sortFn = sortAge(true);
      else if (sortBy === "ADSC") sortFn = sortAge(false);
      // state_country
      else if (sortBy === "SASC") sortFn = sortState(false);
      else if (sortBy === "SDSC") sortFn = sortState(true);
      // team
      else if (sortBy === "TASC") sortFn = sortTeams(false);
      else if (sortBy === "TDSC") sortFn = sortTeams(true);
      // Projection Range
      else if (sortBy === "PRASC") sortFn = sortProjection(false);
      else if (sortBy === "PRDSC") sortFn = sortProjection(true);
      // Date Created
      // Projection Range
      else if (sortBy === "DCASC") sortFn = sortDateCreated(false);
      else if (sortBy === "DCDSC") sortFn = sortDateCreated(true);

      filteredProfs.sort(sortFn);
    }

    const features = {
      Height: heightRange !== DEFAULT_HEIGHT ? heightRange : null,
      Weight: weightRange !== DEFAULT_WEIGHT ? weightRange : null,
      Wingspan: wingspanRange !== DEFAULT_WINGSPAN ? wingspanRange : null,
    };

    if (features.Height) {
      filteredProfs = filteredProfs.filter(
        (profile) =>
          features.Height[0] < feetInchesToInches(profile["Height"]) &&
          feetInchesToInches(profile["Height"]) < features.Height[1]
      );
    }

    if (features.Weight) {
      filteredProfs = filteredProfs.filter(
        (profile) =>
          features.Weight[0] < parseFloat(profile["Weight"]) &&
          parseFloat(profile["Weight"]) < features.Weight[1]
      );
    }

    if (features.Wingspan) {
      filteredProfs = filteredProfs.filter(
        (profile) =>
          features.Wingspan[0] < feetInchesToInches(profile["Wingspan"]) &&
          feetInchesToInches(profile["Wingspan"]) < features.Wingspan[1]
      );
    }

    setFilteredProfiles(filteredProfs);
  };

  const clearFilters = () => {
    setHeightRange(DEFAULT_HEIGHT);
    setWeightRange(DEFAULT_WEIGHT);
    setWingspanRange(DEFAULT_WINGSPAN);
  };

  const getPlayerProfiles = async () => {
    setIsLoading(true);
    try {
      const response = await db.collection("player-profile").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      let filteredData;
      if (isAdmin() || isSuperAdmin()) {
        filteredData = data;
      } else {
        filteredData = data.filter((el) => {
          if (
            el["viewing_privileges"] &&
            el["viewing_privileges"].includes(getUserInfoData().memberType)
          ) {
            return el;
          }
        });
      }

      filteredData.sort((a, b) => a.LastName.localeCompare(b.LastName));

      //filteredData.sort((a, b) => parseInt(a.Year) - parseInt(b.Year));
      if (sortBy === "Year") {
        filteredData.sort((a, b) => parseInt(a.Year) - parseInt(b.Year));
      }

      if (sortBy === "Position") {
        filteredData.sort((a, b) => a.Position.localeCompare(b.Position));
      }

      if (sortBy === "Age") {
        // Sorting by Age (ASC)
        filteredData.sort((a, b) => parseFloat(a.Age) - parseFloat(b.Age));
      }

      if (sortBy === "State") {
        // Sorting by State (ASC)
        filteredData.sort((a, b) =>
          a.hometown_State_Country.localeCompare(b.hometown_State_Country)
        );
      }

      playerProfileData.current = filteredData;
      setFilteredProfiles(filteredData);

      const csvReport = {
        data: data,
        headers: [
          { label: "Player ID", key: "id" },
          { label: "Full Name", key: "Title" },
          { label: "First Name", key: "FirstName" },
          { label: "Last Name", key: "LastName" },
          { label: "Year", key: "Year" },
          { label: "Team", key: "Team" },
          { label: "AAU Team", key: "aauTeam" },
          { label: "AAU Affil", key: "aauAffil" },
          { label: "DOB", key: "DOB" },
          { label: "Age", key: "Age" },
          { label: "Position", key: "Position" },
          { label: "Height", key: "Height" },
          { label: "Weight", key: "Weight" },
          { label: "Wingspan", key: "Wingspan" },
          { label: "Hand", key: "Hand" },
          { label: "Nationality", key: "Country" },
          { label: "Hometown_City", key: "hometown_City" },
          { label: "Hometown_State", key: "hometown_State_Country" },
          { label: "Hometown", key: "fullHometown" },
          { label: "Status", key: "Status" },
          { label: "Status Team", key: "committedTeam" },
          { label: "Projection Range", key: "pRange" },
          { label: "Instagram", key: "Instagram" },
          { label: "Twitter", key: "Twitter" },
          { label: "Snapchat", key: "Snapchat" },
          { label: "Created By", key: "creator" },
          { label: "Viewing Privileges", key: "viewing_privileges" },
        ],
        filename: `pi-test-${new Date().toDateString()}-${new Date().toTimeString()}.csv`,
      };

      setCsvReport(csvReport);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const getFullCountryName = (abbreviation) => {
    const countryObject = countries.find(
      (country) => country.value === abbreviation
    );
    return countryObject ? countryObject.name : abbreviation;
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const csv = e.target.result;
        const jsonArray = await csvtojson().fromString(csv);

        // Update the viewing_privileges field to be an array
        const formattedArray = jsonArray.map((item) => ({
          ...item,
          viewing_privileges: item.viewing_privileges
            ? item.viewing_privileges.split(",").map((value) => value.trim())
            : [], // Assuming the values are separated by commas
          Age: item.Age ? parseFloat(item.Age) || 0 : 0, // Parse Age to float, set to 0 if not available
        }));

        confirm1(formattedArray);
      };
      reader.readAsText(file);
    }
  };

  const calculateAge = (birthday) => {
    return (new Date() - new Date(birthday)) / 31557600000;
  };

  const handleUpdateAgefromDOB = () => {
    // get all users
    db.collection("player-profile")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          const user = doc.data();
          const birthDate = user.DOB;
          const age = calculateAge(birthDate).toFixed(1);

          db.collection("player-profile").doc(doc.id).update({ Age: age });
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  useEffect(() => {
    search_filter_sort(search);
  }, [sortBy, filterBy, search, heightRange, weightRange, wingspanRange]);
  return (
    <div className={styles.container}>
      <Header search={false} />

      {/* content */}
      <div className={styles.title}>
        <h2>Smart Search</h2>
        <hr />
      </div>
      <div className="my-3 d-flex justify-content-between">
        <div className={hStyles.searchContainer}>
          <FaSearch />
          <input
            style={{
              marginLeft: "10px",
            }}
            type="text"
            placeholder="Quick Player Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <Button
          label="Clear Filters"
          onClick={clearAllFilters}
          className="p-button-secondary"
          style={{
            backgroundColor: "black",
            marginRight: "10px",
          }}
        />

        {isSuperAdmin() && (
          <div>
            {/* <Button
              label="Update Age from DOB"
              onClick={handleUpdateAgefromDOB}
            ></Button> */}
            <input
              type="file"
              ref={selectCSV}
              // only accept .csv files
              accept=".csv"
              style={{
                display: "none",
              }}
              onChange={handleFileUpload}
            />
            <Button
              onClick={() => selectCSV.current.click()}
              icon="pi pi-check"
              label="Import"
              severity="success"
              style={{
                backgroundColor: "black",
                //   borderColor: "green",
              }}
              size="small"
            ></Button>
            {csvReport && (
              <CSVLink {...csvReport}>
                <Button
                  size="small"
                  icon="pi pi-times"
                  label="Export"
                  severity="danger"
                  style={{
                    backgroundColor: "black",
                    marginLeft: "10px",
                  }}
                ></Button>
              </CSVLink>
            )}{" "}
            <Button
              onClick={confirm2}
              icon="pi pi-times"
              label="Delete"
              severity="danger"
              style={{
                marginLeft: "10px",
                backgroundColor: "red",
                borderColor: "red",
              }}
              size="small"
            ></Button>
          </div>
        )}
      </div>

      {/* filter */}

      {/* <MultiSelect maxSelectedLabels={0} placeholder="Country" value={filterBy.country} optionLabel="name" optionValue="value" options={countries} onChange={(e) => setFilterBy(prev=>({...prev, country: e.value}))} /> */}

      <div className="d-flex">
        <FiltersComponent
          options={sortByElements}
          handleChange={(option) => setSortBy(option.value)}
          currentFilter={sortBy}
          heightRange={heightRange}
          handleHeightChange={handleHeightChange}
          weightRange={weightRange}
          handleWeightChange={handleWeightChange}
          wingspanRange={wingspanRange}
          handleWingspanChange={handleWingspanChange}
          clearFilters={clearFilters}
        />

        {[
          { name: "team", label: "Team", options: teams },
          { name: "country", label: "Nationality", options: countries },
          {
            name: "stateandcountry",
            label: "State",
            options: stateandcountries,
          },
          { name: "position", label: "Position", options: positions },
          { name: "year", label: "Year", options: years },
          { name: "aauTeam", label: "AAU Team", options: aauTeams },
          { name: "affliation", label: "Affiliation", options: affliations },
          { name: "status", label: "Status", options: statuses },
        ].map((el) => (
          <MultiSelect
            key={el.name}
            className={styles.card}
            maxSelectedLabels={0}
            placeholder={el.label}
            name={el.name}
            value={filterBy[el.name]}
            optionLabel="name"
            optionValue={el.name === "team" ? "name" : "value"}
            options={el.options}
            onChange={(e) =>
              setFilterBy((prev) => ({
                ...prev,
                [e.target.name]: e.value,
              }))
            }
            filter
          />
        ))}
      </div>

      <Toast ref={toast} />
      <ConfirmDialog />
      {/* table */}
      <div>
        <Table
          responsive
          style={{
            borderRadius: "10px",
            overflow: "hidden",
            marginTop: "20px",
          }}
        >
          <tbody>
            {filteredProfiles.length === 0 && !isLoading ? (
              <td>
                <p>No Data to Display</p>
              </td>
            ) : (
              filteredProfiles.map((item) => (
                <tr
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const account = JSON.parse(localStorage.getItem("account"));
                    if (isAdmin() || isSuperAdmin()) {
                      window.open(
                        `/player-profile/detail/${item.id}`,
                        "_blank"
                      ); // Open in new tab
                    } else {
                      window.open(
                        `/player-profile/preview/${item.id}`,
                        "_blank"
                      ); // Open in new tab
                    }
                  }}
                  key={item.id}
                  className={styles.tableRow}
                >
                  <td>
                    <Row className="p-2">
                      <Col md={2}>
                        <Image
                          width="50px"
                          height="50px"
                          roundedCircle
                          src={item?.Headshot ? item.Headshot : previewImage}
                          onError={(e) => {
                            e.target.src = previewImage; // Set default image if loading fails
                          }}
                          style={{
                            objectFit: "cover",
                          }}
                        />
                      </Col>
                      <Col md={10}>
                        <div
                          className={styles.tablePersonInfo}
                          style={{
                            width: "185px",
                            marginLeft: "15px",
                          }}
                        >
                          <h4>
                            {item.FirstName} {item.LastName}
                          </h4>
                          {(item.Team === "Transfer Portal" && (isAdmin() || isSuperAdmin())) ? (
                              <h6>{item.Team}</h6>
                            ) : (item.Team !== "Transfer Portal" && <h6>{item.Team}</h6>)}
                          <p>
                            {item.hometown_City &&
                              !item.hometown_State_Country &&
                              item.hometown_City}
                            {!item.hometown_City &&
                              item.hometown_State_Country &&
                              item.hometown_State_Country}
                            {item.hometown_City &&
                              item.hometown_State_Country && (
                                <>
                                  {item.hometown_City},{" "}
                                  {item.hometown_State_Country}
                                </>
                              )}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-${item.id}`}>
                          {getFullCountryName(item.Country)}
                        </Tooltip>
                      }
                    >
                      <div
                        className={styles.tableData}
                        style={{
                          width: "70px",
                        }}
                      >
                        <p>Nationality</p>
                        <h4>{item.Country ? item.Country : "--"}</h4>
                      </div>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <div className={styles.tableData}>
                      <p>Position</p>
                      <h4>{item.Position ? item.Position : "--"}</h4>
                    </div>
                  </td>
                  <td>
                    <div className={styles.tableData}>
                      <p>Year</p>
                      <h4>{item.Year ? item.Year : "--"}</h4>
                    </div>
                  </td>
                  <td>
                    <div className={styles.tableData}>
                      <p>Height</p>
                      <h4>{item.Height ? item.Height : "--"}</h4>
                    </div>
                  </td>
                  <td>
                    <div
                      className={styles.tableData}
                      style={{
                        width: "70px",
                      }}
                    >
                      <p>Weight</p>
                      <h4>{item.Weight ? item.Weight : "--"}</h4>
                    </div>
                  </td>
                  <td>
                    <div className={styles.tableData}>
                      <p>Wingspan</p>
                      <h4>{item.Wingspan ? item.Wingspan : "--"}</h4>
                    </div>
                  </td>
                  <td>
                    <div className={styles.tableData}>
                      <p>DOB</p>
                      <h4>{item.DOB ? moment(item.DOB).format("l") : "--"}</h4>
                    </div>
                  </td>
                  <td>
                    <div className={styles.tableData}>
                      <p>Age</p>
                      <h4>
                        {item.Age !== 0.0 && !isNaN(Number(item.Age))
                          ? Number(item.Age).toFixed(1)
                          : "--"}
                      </h4>
                      {/* <h4>{calculateAge(item.DOB).toFixed(1)}</h4> */}
                    </div>
                  </td>
                  <td>
                    <div className={styles.tableData}>
                      <p>AAU Team</p>
                      <h4 style={{ textAlign: "center" }}>
                        {item.aauTeam ? item.aauTeam : "--"}
                      </h4>
                    </div>
                  </td>
                  <td>
                    <div className={styles.tableData}>
                      <p>Affiliation</p>
                      <h4 style={{ textAlign: "center" }}>
                        {item.aauAffil && item.aauAffil !== "None"
                          ? item.aauAffil
                          : "--"}
                      </h4>
                    </div>
                  </td>
                  <td>
                    <div className={styles.tableData}>
                      <p>Status</p>
                      <h4 style={{ textAlign: "center" }}>
                        {item.Status !== "Undecided"
                          ? `${item.Status}: ${item.committedTeam}`
                          : item.Status}
                      </h4>
                    </div>
                  </td>

                  <td>
                    <div className={styles.tableData}>
                      <p style={{ textAlign: "center" }}>Projection Range</p>
                      <h4 style={{ textAlign: "center" }}>
                        {item.pRange && item.Prlow !== "" && item.Prhigh !== ""
                          ? item.Prlow === item.Prhigh
                            ? item.Prhigh
                            : `${item.Prlow} to ${item.Prhigh}`
                          : "-"}
                      </h4>
                    </div>
                  </td>
                  <td
                    style={{
                      backgroundColor: "#000",
                    }}
                  >
                    <div className={styles.tableDataDark}>
                      <p>P.I. Score</p>
                      <h4 style={{ textAlign: "center" }}>Coming Soon</h4>
                      {/*<h4>{item.Piscore}</h4>*/}
                    </div>
                  </td>
                </tr>
              ))
            )}{" "}
          </tbody>
        </Table>
      </div>
      {isLoading && <p>Loading...</p>}
      <div ref={observerTarget}></div>
    </div>
  );
};

const sortTeams = (isReverse) => {
  if (isReverse) {
    return function (a, b) {
      return b.Team.localeCompare(a.Team);
    };
  }
  return function (a, b) {
    return a.Team.localeCompare(b.Team);
  };
};

const sortNames = (isReverse, sortByLastName) => {
  if (isReverse) {
    return function (a, b) {
      const nameA = sortByLastName
        ? b.LastName.toLowerCase()
        : b.FirstName.toLowerCase();
      const nameB = sortByLastName
        ? a.LastName.toLowerCase()
        : a.FirstName.toLowerCase();
      return nameA.localeCompare(nameB);
    };
  }
  return function (a, b) {
    const nameA = sortByLastName
      ? a.LastName.toLowerCase()
      : a.FirstName.toLowerCase();
    const nameB = sortByLastName
      ? b.LastName.toLowerCase()
      : b.FirstName.toLowerCase();
    return nameA.localeCompare(nameB);
  };
};

const filterProfiles = (profiles, filterBy) => {
  let filters = 0;
  Object.values(filterBy).forEach((v) => (filters += v.length));
  if (filters === 0) return profiles;
  else
    return profiles.filter((p) => {
      // Check if all selected filter criteria match the corresponding properties of the profile
      const countryMatch =
        filterBy.country.length === 0 || filterBy.country.includes(p.Country);
      const stateandcountriesMatch =
        filterBy.stateandcountry.length === 0 ||
        filterBy.stateandcountry.includes(p.hometown_State_Country);
      const positionMatch =
        filterBy.position.length === 0 ||
        filterBy.position.includes(p.Position);
      const yearMatch =
        filterBy.year.length === 0 || filterBy.year.includes(p.Year);
      const teamMatch =
        filterBy.team.length === 0 || filterBy.team.includes(p.Team);
      const aauTeamMatch =
        filterBy.aauTeam.length === 0 || filterBy.aauTeam.includes(p.aauTeam);
      const statusMatch =
        filterBy.status.length === 0 || filterBy.status.includes(p.Status);
      const affiliationMatch =
        filterBy.affliation.length === 0 ||
        filterBy.affliation.includes(p.aauAffil);

      // Return true if all filter criteria match
      return (
        countryMatch &&
        stateandcountriesMatch &&
        positionMatch &&
        yearMatch &&
        teamMatch &&
        aauTeamMatch &&
        statusMatch &&
        affiliationMatch
      );
    });
};

const sortYears = (isReverse) => {
  if (isReverse) {
    return function (a, b) {
      return compareYears(b.Year, a.Year);
    };
  }
  return function (a, b) {
    return compareYears(a.Year, b.Year);
  };
};

// Function to compare mixed year values
const compareYears = (yearA, yearB) => {
  const yearIntA = parseInt(yearA);
  const yearIntB = parseInt(yearB);

  if (!isNaN(yearIntA) && !isNaN(yearIntB)) {
    // Both values are integers, so compare them numerically
    return yearIntA - yearIntB;
  } else if (!isNaN(yearIntA)) {
    // Only yearA is an integer, so it comes first
    return -1;
  } else if (!isNaN(yearIntB)) {
    // Only yearB is an integer, so it comes first
    return 1;
  } else {
    // Both values are non-integer or non-numeric, so compare them as strings
    return yearA.localeCompare(yearB);
  }
};

// Position Sort function
const sortPositions = (isReverse) => {
  if (isReverse) {
    return function (a, b) {
      return b.Position.localeCompare(a.Position);
    };
  }
  return function (a, b) {
    return a.Position.localeCompare(b.Position);
  };
};

const sortAge = (isReverse) => {
  if (isReverse) {
    return function (a, b) {
      return b.Age - a.Age;
    };
  }
  return function (a, b) {
    return a.Age - b.Age;
  };
};

const sortState = (isReverse) => {
  if (isReverse) {
    return function (a, b) {
      return b.hometown_State_Country.localeCompare(a.hometown_State_Country);
    };
  }
  return function (a, b) {
    return a.hometown_State_Country.localeCompare(b.hometown_State_Country);
  };
};

const sortDateCreated = (isReverse) => {
  console.log("Sorting by Date Created");
  return function (a, b) {
    // Check if dateCreated is a Firebase Timestamp and convert to Date object
    const dateA = a.dateCreated && a.dateCreated.toDate ? a.dateCreated.toDate() : new Date(a.dateCreated);
    const dateB = b.dateCreated && b.dateCreated.toDate ? b.dateCreated.toDate() : new Date(b.dateCreated);

    // Check if either date is invalid
    const isDateAValid = !isNaN(dateA);
    const isDateBValid = !isNaN(dateB);

    if (isDateAValid && isDateBValid) {
      if (isReverse) {
        return dateB - dateA; // Sort in descending order
      }
      return dateA - dateB; // Sort in ascending order
    } else if (isDateAValid && !isDateBValid) {
      // Push invalid dates to the bottom
      return -1;
    } else if (!isDateAValid && isDateBValid) {
      return 1;
    } else {
      // Both dates are invalid, maintain original order
      return 0;
    }
  };
};


const projectionRankAsc = {
  "HM+": 1,
  HM: 2,
  "HM-": 3,
  "MM+": 4,
  MM: 5,
  "MM-": 6,
  "LM+": 7,
  LM: 8,
  "LM-": 9,
};

const projectionRankDesc = {
  "LM-": 1,
  LM: 2,
  "LM+": 3,
  "MM-": 4,
  MM: 5,
  "MM+": 6,
  "HM-": 7,
  HM: 8,
  "HM+": 9,
};

const sortProjection = (isReverse) => {
  const rank = isReverse ? projectionRankDesc : projectionRankAsc;

  return function (a, b) {
    const getRank = (proj) => (proj ? rank[proj] : Number.MAX_SAFE_INTEGER);

    // Handle profiles with blank Prhigh
    if (!a.Prhigh && !b.Prhigh) return 0;
    if (!a.Prhigh) return 1;
    if (!b.Prhigh) return -1;

    // First, compare based on Prhigh rank
    const rankAHigh = getRank(a.Prhigh);
    const rankBHigh = getRank(b.Prhigh);
    if (rankAHigh !== rankBHigh) return rankAHigh - rankBHigh;

    // If Prhigh ranks are the same, check if Prhigh and Prlow are equal
    const aIsEqual = a.Prlow === a.Prhigh;
    const bIsEqual = b.Prlow === b.Prhigh;
    if (aIsEqual && !bIsEqual) return -1;
    if (!aIsEqual && bIsEqual) return 1;

    // If both are ranges, compare based on Prlow rank
    const rankALow = getRank(a.Prlow);
    const rankBLow = getRank(b.Prlow);
    return rankALow - rankBLow;
  };
};
