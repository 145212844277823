import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.scss";
import moment from "moment";
import { Table, Image, Row, Col } from "react-bootstrap";
import { useAuth } from "../../Context/AuthContext";
import { db } from "../../firebase";
import { useHistory } from "react-router-dom";

export default function WatchList() {
  
  const history = useHistory();
  const { isAdmin, isSuperAdmin,  currentUser, getUserInfoData } = useAuth();
  
  const [filteredProfiles, setFilteredProfiles] = useState([]);

  const calculateAge = (birthday) => {
    return (new Date() - new Date(birthday)) / 31557600000;
  };

  useEffect(() => {
    fetchWatchList();
  }, []);

  if (filteredProfiles.length === 0) {
    return <></>;
  }

  async function fetchWatchList() {
    // fetch from firebase db
    // id of player
    const document = await db.collection("watchlist").get();

    const watchlist = [];

    document.docs.map((doc) => {
      if (doc.data().subscribers.includes(currentUser.uid)) {
        watchlist.push(doc.id);
      }
    });
    console.log(watchlist, "watchlist");

    const response = await db.collection("player-profile").get();
    const data = [];

    response.docs.map((doc) => {
      if (watchlist.includes(doc.id)) {
        data.push({ id: doc.id, ...doc.data() });
      }
    });
    setFilteredProfiles(data);
  }
  return (
    <div className={styles.watchlist}>
      <p>Watchlist</p>

      <Table
        responsive
        style={{
          borderRadius: "10px",
          overflow: "hidden",
          marginTop: "20px",
        }}
      >
        <tbody>
          {filteredProfiles &&
            filteredProfiles.map((item) => (
              <tr
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  history.push(`/player-profile/preview/${item.id}`);
                }}
                key={item.id}
                className={styles.tableRow}
              >
                <td>
                  <Row className="p-2">
                    <Col md={4}>
                      <Image
                        width="50px"
                        height="50px"
                        roundedCircle
                        src={item?.Headshot}
                        style={{
                          objectFit: "contain",
                        }}
                      />
                    </Col>
                    <Col md={8}>
                      <div className={styles.tablePersonInfo}>
                        <h4>
                          {item.FirstName} {item.LastName}
                        </h4>
                        {(item.Team === "Transfer Portal" && (isAdmin() || isSuperAdmin())) ? (
                              <h6>{item.Team}</h6>
                            ) : (item.Team !== "Transfer Portal" && <h6>{item.Team}</h6>)}
                        <p>
                          {item.hometown_City}, {item.hometown_State_Country}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </td>
                <td>
                  <div className={styles.tableData}>
                    <p>Country</p>
                    <h4>{item.Country}</h4>
                  </div>
                </td>
                <td>
                  <div className={styles.tableData}>
                    <p>Position</p>
                    <h4>{item.Position}</h4>
                  </div>
                </td>
                <td>
                  <div className={styles.tableData}>
                    <p>Year</p>
                    <h4>{item.Year}</h4>
                  </div>
                </td>
                <td>
                  <div className={styles.tableData}>
                    <p>DOB</p>
                    <h4>{moment(item.DOB).format("l")}</h4>
                  </div>
                </td>
                <td>
                  <div className={styles.tableData}>
                    <p>Age</p>
                    {/* <h4>{Number(item.Age).toFixed(1)}</h4> */}
                    <h4>{calculateAge(item.DOB).toFixed(1)}</h4>
                  </div>
                </td>
                <td>
                  <div className={styles.tableData}>
                    <p>AAU Team</p>
                    <h4>{item.aauTeam}</h4>
                  </div>
                </td>
                <td>
                  <div className={styles.tableData}>
                    <p>Affiliation</p>
                    <h4>{item.aauAffil}</h4>
                  </div>
                </td>
                <td>
                  <div className={styles.tableData}>
                    <p>Status</p>
                    <h4>
                      {item.Status}{" "}
                      {item.Status !== "Undecided" && item.committedTeam}
                    </h4>
                  </div>
                </td>
                <td
                  style={{
                    backgroundColor: "#000",
                  }}
                >
                  <div className={styles.tableDataDark}>
                    <p>P.I. Score</p>
                    {/* <h4>{item.Piscore}</h4> */}
                    <h4>Coming Soon</h4>
                  </div>
                </td>
              </tr>
            ))}{" "}
        </tbody>
      </Table>
    </div>
  );
}
