import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.scss";
import { useAuth } from "../../Context/AuthContext";
import { db } from "../../firebase";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { MdRemoveRedEye } from "react-icons/md";
import { ViewReportModal } from "../PlayerPreview/ViewReportModal";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ITEMS_PER_PAGE = 10;

export default function RecentActivity() {
  const history = useHistory();
  const { getUserInfoData } = useAuth();
  const [filteredReports, setFilteredReports] = useState([]);
  const [lastVisible, setLastVisible] = useState(null); // Keep track of the last fetched document
  const [selectedViewReport, setSelectedViewReport] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state for initial render
  const [loadingMore, setLoadingMore] = useState(false); // Loading state for Load More

  async function handleGetRecentActivity(loadMore = false) {
    if (!loadMore) setLoading(true); // Only show skeleton on initial load
    else setLoadingMore(true); // For subsequent loads

    let query = db.collection("reports").orderBy("reportDate", "desc").limit(ITEMS_PER_PAGE);

    // If loading more, start after the last visible document
    if (loadMore && lastVisible) {
      query = query.startAfter(lastVisible);
    }

    const document = await query.get();

    const response = await db.collection("player-profile").get();
    const profileData = response.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });

    let profileIds = [];
    profileData.forEach((el) => {
      if (
        ["admin", "super-admin"].includes(getUserInfoData().memberType) ||
        el["viewing_privileges"] &&
        el["viewing_privileges"].includes(getUserInfoData().memberType)
      ) {
        profileIds.push(el.id);
      }
    });

    const recentActivityList = document.docs
      .filter((doc) => profileIds.includes(doc.data().playerProfile))
      .map((doc) => {
        let player = profileData.find((el) => el.id === doc.data().playerProfile);
        return { id: doc.id, ...doc.data(), player };
      });

    setFilteredReports((prevReports) => 
      loadMore ? [...prevReports, ...recentActivityList] : recentActivityList
    );
    setLastVisible(document.docs[document.docs.length - 1]); // Update last visible document
    setLoading(false);
    setLoadingMore(false);
  }

  useEffect(() => {
    handleGetRecentActivity();
  }, []);

  const handleLoadMore = () => {
    handleGetRecentActivity(true); // Pass true to indicate loading more
  };

  const handleViewReport = (reportId) => {
    setSelectedViewReport(reportId);
  };

  const renderSkeletonLoader = () => (
    <tbody>
      {[...Array(ITEMS_PER_PAGE)].map((_, index) => (
        <tr key={index} className={styles.skeletonRow}>
          <td colSpan="4">
            <div className={styles.skeletonCell}></div>
          </td>
        </tr>
      ))}
    </tbody>
  );

  return (
    <div className={styles.feed}>
      {selectedViewReport && (
        <ViewReportModal
          show={selectedViewReport}
          handleClose={() => setSelectedViewReport(null)}
          reportData={
            filteredReports.find((rep) => rep.id === selectedViewReport)
          }
          firstName={filteredReports.find((rep) => rep.id === selectedViewReport).player.FirstName} 
          lastName={filteredReports.find((rep) => rep.id === selectedViewReport).player.LastName} 
        />
      )}

      <p>Recent Activity</p>
      <div className={styles.tableContainer}>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Player</th>
              <th>Report Type</th>
              <th>View</th>
            </tr>
          </thead>
          {loading ? (
            renderSkeletonLoader() // Show skeleton loader only during the initial load
          ) : (
            <tbody>
              {filteredReports.map((item, index) => (
                <tr key={index}>
                  <td>{moment(item.reportDate).format("MM/DD/YYYY")}</td>
                  <td>
                    <Link to={`/player-profile/preview/${item.player.id}`}>
                      {item.player.FirstName}&nbsp;{item.player.LastName}
                    </Link>
                  </td>
                  <td>{item.type}</td>
                  <td>
                    <div className={styles.actionIcon}>
                      <MdRemoveRedEye onClick={() => handleViewReport(item.id)} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        <div className={styles.centeredButton}>
          {loadingMore ? (
            <p>Loading more...</p> // Display "Loading more..." during the Load More fetch
          ) : (
            <button onClick={handleLoadMore}>Load More</button>
          )}
        </div>
      </div>
    </div>
  );
}
